<template>
  <div>
    <h4>help content</h4>
  </div>
</template>

<style scoped>
div {
  margin: 50px;
  display: flex;
  flex-direction: column;
}

.el-link {
  margin-top: 30px;
  font-size: 30px;
  line-height: 50px;
}

.el-link .el-icon--right.el-icon {
  vertical-align: text-bottom;
}
</style>

<script>

</script>
